// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';

// Your web app's Firebase configuration (replace with your actual config)
const firebaseConfig = {
    apiKey: "AIzaSyDRZ6gFj2XGKTdCpAfBetFOxcpxWCB43Xk",
    authDomain: "housewife-3b13a.firebaseapp.com",
    projectId: "housewife-3b13a",
    storageBucket: "housewife-3b13a.appspot.com",
    messagingSenderId: "746257604137",
    appId: "1:746257604137:web:bd8e7488524e07cc1f0638",
    measurementId: "G-ZD8PEG4H2Y"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// If running on localhost, connect to the Firestore emulator
if (location.hostname === "localhost") {
  console.log("Using Firestore Emulator");
  connectFirestoreEmulator(db, 'localhost', 8080);
}

export { db };
