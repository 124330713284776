<template>
 <div class="container">
     <div class="row">
         <div class="col-12">
            <h3 class="title mb-4">กรุณาระบุเบอร์โทรศัพท์ที่ลงทะเบียน</h3>
             <!-- Result Message -->
               <div class="input-group mb-3">
                <input
                type="tel"
                class="form-control form-control-lg"
                placeholder="ระบุเบอร์โทรที่ลงทะเบียนไว้"
                aria-label="Search"
                v-model="searchPhone"
                @keyup.enter="searchDatabase"
                />
                <button class="btn btn-outline-secondary" type="button" @click="searchDatabase">
                    ค้นหา
                </button>
            </div>
            <p>หากไม่แน่ใจ สามารถเช็คจากรายชื่อ<br/>ผู้ที่ได้รับของขวัญทั้งหมด <a href="http://bit.ly/3RY7Dqy" target="_blank" title="รายชื่อผู้ที่ได้รับของขวัญทั้งหมด">คลิกที่นี่</a></p>
            <hr/>
            <!-- Display Results -->
            <div v-if="winners.length > 0">
            <ul class="list-unstyled">
                <li v-for="(winner, index) in winners" :key="index">
                    <p class="mb-1">วาสนาของคุณ</p>
                    <p class="h4 mb-3">{{ winner.name }}</p>
                    <p class="alert alert-success fs-4" role="alert">{{ winner.prize }} <br/> <span v-show="winner.value != ''"> มูลค่า {{ winner.value }} บาท</span></p>
                    <p class="mb-1">จาก</p>
                    <p class="h4 mb-3">{{ winner.brand_name }}</p>
                    <p>ติดต่อรับของขวัญได้ทาง</p>
                    <p class="alert alert-success fs-4" role="alert">
                        <div v-if="winner.brand_social === 'facebook'">
                        Facebook Page
                        </div>
                        <div v-else-if="winner.brand_social === 'line'">
                        LINE OA
                        </div>
                        <div v-else-if="winner.brand_social === 'instagram'">
                        Instagram
                        </div>
                        <div v-else-if="winner.brand_social === 'email'">
                        อีเมล
                        </div>
                        <a :href="`${winner.brand_link}`">{{ winner.brand_contact }}</a>
                    </p>
                    <img :src="require(`@/assets/${winner.brand_id}.jpg`)" class="img-fluid" alt="">
                    <div class="alert alert-light text-start" role="alert">
                        <span v-html="winner.brand_caption_html"></span>
                    </div>
                </li>
            </ul>
            </div>

            <!-- Display Message If Not Found -->
            <div v-show="winners.length < 1 && seen">
                <p class="mb-1">วาสนาของคุณ ได้รับ</p>
                <p class="alert alert-success fs-4" role="alert">ตารางสีเสื้อมงคลประจำปี 2567</p>
                <p class="mb-1">จาก</p>
                <p class="h4 mb-3">แอดมินกลุ่มงานบ้านที่รัก</p>
                <p>ดาวน์โหลดไฟล์สำหรับพิมพ์ได้ที่นี่</p>
                <p class="alert alert-success fs-4" role="alert">
                    <button type="button" class="btn btn-link" @click="downloadImage('https://firebasestorage.googleapis.com/v0/b/housewife-3b13a.appspot.com/o/lucky-shirt-color-2024.jpg?alt=media&token=0398289b-b66d-4754-922a-f6ccccce386b')">ดาวน์โหลดไฟล์</button>
                </p>
                <p v-show="isInAppBrowser()" class="blink_me text-danger">คุณกำลังเปิดเว็บนี้ด้วย In-app browser โปรดใช้เบราว์เซอร์ของมือถือเพื่อดาวน์โหลดไฟล์</p>
            </div>
            <img v-show="winners.length < 1 && seen" :src="require(`@/assets/lucky-shirt-color-2024.jpg`)" class="img-fluid" alt="">
            <img v-show="winners.length < 1" src="@/assets/logo.png" alt="" class="mt-4 img-fluid">
         </div>
     </div>
 </div>
</template>

<script>
import { db } from '@/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import confetti from 'canvas-confetti';

export default {
  data() {
    return {
      searchPhone: '',
      winners: [],
      message: '',
      seen: false
    };
  },
  methods: {
    anonymizePhoneNumber() {
      if (this.searchPhone.length >= 4) {
        // Replace the last four characters with asterisks
        const length = this.searchPhone.length;
        this.searchPhone = `${this.searchPhone.substring(0, length - 4)}****`;
      }
    },
    launchConfetti() {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });
    },
    isInAppBrowser() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /FBAN|FBAV|Instagram|Line/.test(userAgent); // Add other in-app identifiers as needed
    },
    async downloadImage() {
      try {
        if (this.isInAppBrowser()) {
            // Open image directly for manual save by user if in an in-app browser.
            window.open('https://firebasestorage.googleapis.com/v0/b/housewife-3b13a.appspot.com/o/lucky-shirt-color-2024.jpg?alt=media&token=0398289b-b66d-4754-922a-f6ccccce386b');
        } else {
            const imageUrl = 'https://firebasestorage.googleapis.com/v0/b/housewife-3b13a.appspot.com/o/lucky-shirt-color-2024.jpg?alt=media&token=0398289b-b66d-4754-922a-f6ccccce386b'; // Replace with your image URL
            const imageName = 'lucky-color-shirt-2024.jpg'; // The filename for downloaded image

            // Fetch the image as a Blob
            const response = await fetch(imageUrl);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            
            const blob = await response.blob();

            // Create an object URL from the Blob
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a temporary link element and trigger the download
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = imageName;

            document.body.appendChild(link);
            
            link.click(); // Clicking triggers download

            // Clean up after triggering download
            window.URL.revokeObjectURL(blobUrl); 
            document.body.removeChild(link);

            // Track the event with Google Analytics
            this.trackDownloadEvent('lucky-shirt-color-2024.jpg'); 
        }
      } catch (error) {
          console.error(error);
      }
    },
    async searchDatabase() {
        this.launchConfetti();
        try {
            // Reference to "winners" collection
            const winnersRef = collection(db, 'winners');
            
            // Query "winners" where "phone" matches searchPhone
            const winnersQuery = query(winnersRef, where('phone', '==', this.searchPhone));
            
            // Execute the query
            const winnersSnapshot = await getDocs(winnersQuery);

            if (!winnersSnapshot.empty) {
            let promises = [];
            
            winnersSnapshot.forEach((winnerDoc) => {
                // For each winner, get the associated prize details based on brand_id.
                const prizeDetailRef = collection(db, 'prize_detail');
                
                // Create a new promise to retrieve details for this specific brand_id
                const prizeDetailPromise = getDocs(query(prizeDetailRef, where('brand_id', '==', winnerDoc.data().brand_id)));
                
                promises.push(prizeDetailPromise);
            });

            // Wait for all prize detail queries to complete
            const prizeDetailsSnapshots = await Promise.all(promises);
            
            // Combine each winner with their respective prize details into an array of objects.
            this.winners = winnersSnapshot.docs.map((doc, index) => ({
                ...doc.data(),
                ...prizeDetailsSnapshots[index].docs[0].data(),  // Assuming there's one document per brand_id in prize_details.
            }));

                this.seen = true
                this.anonymizePhoneNumber();
            
            } else {
                this.winners = [];
                this.message = 'Sorry, no winners found with that phone number.';
                this.seen = true
                this.anonymizePhoneNumber();
            }
        } catch (error) {
            console.error("Error searching documents:", error);
        }
    },
    trackDownloadEvent(imagePath) {
      if (typeof gtag === 'function') {
          gtag('event', 'download_image', {
            event_category: 'Images',
            event_label: imagePath,
            value: 1
          });
      }
    }
  }
};
</script>

<style scoped>

.blink_me {
  animation: blinker .5s linear 5;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.container {
    max-width: 480px !important;
    width: 100%;
}

.title {
    color: #0C603A;
    font-weight: bold;
}

p {
    color: #0C603A;
    font-weight: bold;
}
.btn {
    background-color: #0C603A;
    color: #fff;
}

.btn:hover {
 background-color: #172F1E;
}

.btn-link {
    font-size: 22px;
}

.btn-link:hover {
    background-color: #0C603A;
    color: #fff;
}

.alert-success {
    background-color: #0C603A;
    color: #fff;
    border-radius: 50px;
}

.alert-success a {
    color: #fff;
}
</style>
